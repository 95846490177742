<div class="page page-terminus-details" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0">
            <img src="./assets/images/terminus-details-bg2.jpg">
        </div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <p class="boss"><img src="./assets/images/boss.jpg"></p>
        <div class="text-block">
            <h1 class="title">Victor AI</h1>
            <p class="desc">Founder and CEO, Terminus Group</p>
        </div>
        <div class="text-block ">
            <p class="desc dot">Distinguished Entrepreneur: Founder and CEO of Terminus Group</p>
            <p class="desc dot">10+ years in PE investment with over 100 deals and an AUM over USD 10B</p>
            <p class="desc dot">Award Winner: Forbes, Fortune, Hurun Report, Davos China, Harvard Business Review </p>
        </div>
        <div class="text-block">
            <p class="desc">Victor AI is a successful and forward-thinking entrepreneur. He founded Terminus Group, a platform pioneering the new wave of high technology, in which he and his team anticipated the industrial transition from internet to intelligence, five years ago. In August 2019, Terminus Group received a strategic investment from a group of renowned investors including JD.com (NASDAQ:JD), iFlytek (002230.SZ) and also continues to be backed by early investors, including China Everbright Limited (165.HK), IDG Capital and CITIC Group. Under his leadership, Terminus Group has been selected by Gartner for special reference six times, as the most outstanding AIoT (AI+IoT) enterprise in Asia</p>
            <br>
            <p class="desc">Besides, Victor AI has had a distinguished career developing the private equity platform. Over the past 12 years, he has closed over 100 deals in private equity investment with an AUM exceeding USD 10B. Over the last four years he has closed a series of signature deals in the new economy sector including amongst others, Meituan (3690.HK), iQiyi (NASDAQ:IQ), NIO (NYSE:NIO), SRX (605168.SH) SenseTime, Netease Cloud Music, Xpeng Motors, Miss Fresh as well as JD Logistics and China UnionPay.</p>
            <br>
            <p class="desc">His investment and entrepreneurial work has earned distinguished merit amongst his global industrial peers:</p>
            <br>
            <p class="desc">2019</p>
            <p class="desc dot">Fortune magazine’s China’s 40 under 40 list </p>
            <p class="desc dot">Forbes Top 100 Investors in China</p>
            <p class="desc dot">Top 20 Chinese Digital Transformation Pioneer by Harvard Business Review</p>
            <p class="desc dot">Top 5 PE Investors in China by China Renaissance</p>
            <p class="desc dot">Top 30 Middle-Age Investors and the Top 100 Most Popular Investors in China by 36kr</p>
            <br>
            <p class="desc">2018</p>
            <p class="desc dot">Forbes Top 30 PE in China</p>
            <p class="desc dot">Top 5 PE Investors in China by China Renaissance</p>
            <p class="desc dot">Top 10 Best New Prominent Investors by Hurun Report</p>
            <p class="desc dot">Terminus Group as TOP 50 AI Company by Davos China</p>
            <br>
            <p class="desc">Vicotr AI started his career at J.P. Morgan Investment Banking Division and worked for M&A team in New York and Real Estate coverage team in HK.</p>
            <br>
            <p class="desc">
                He holds an Executive MBA degree from Cheung Kong Graduate School of Business, Master of Finance from Washington University in St. Louis, and Bachelor Degree of Economics from Simon Fraser University from Vancouver.</p>
        </div>
    </div>
</div>
