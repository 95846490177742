<div class="page page-terminus-details" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">About </p>
            <p class="big">Terminus</p>
        </div>
        <p class="desc">
            "Terminus Group was established with a team of ambitious technologists. Our shared vision was to build a platform that bridged the gap between AI and IoT (AIoT) in anticipation of what we now know as the "intelligence economy".<br/>
            <br/>
            Today, Terminus Group is exploring the boundaries of AIoT to make the world a more intelligent and connected place. We could not be more excited about our future. At our core, we are a dreamer of a future city.<br/>
            <br/>
            After all, without big ideas how can we hope to change the world around us for the better. "<br/>
            <br/>
            ------Victor Ai, Founder and CEO, Terminus Group
        </p>
        <p class="segmentation">••••••</p>
        <p class="desc blue">Leveraging AIoT(artifificial intelligence and Internet of Things) technology, Terminus Group provides smart city solutions.</p>
        <div  class="swiper-container" [swiper]="config" [(index)]="index">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of slides" [routerLink]="item.path">
                    <img src="./assets/images/terminus-details-image{{item.index}}.jpg">
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
