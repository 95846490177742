import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import Ajax from "../../public/Ajax";
@Component({
  selector: 'app-module3',
  templateUrl: './module3.component.html',
  styleUrls: ['./module3.component.css']
})

export class Module3Component extends ModuleComponent implements OnInit {
  loadingEnd=false;
  newsList:any[]=[];
  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(e=>this.getRecommendedNews(),1000);
  }
  async getRecommendedNews(){
    let {data}=await Ajax.post('get-news',{pageIndex:1,pageSize:100000});
    this.newsList=data.showreel||[];
    this.loadingEnd=true;
  }
}
