import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import Ajax from "../../public/Ajax";
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent extends ModuleComponent implements OnInit {
  @ViewChild('iframe') iframe:ElementRef;
  newItem:any={};
  iframeStyle:any={
    height:"calc(100vh - 9rem)"
  };
  safeUrl;
  constructor(activateRoute:ActivatedRoute,router:Router,private sanitizer: DomSanitizer) {
    super(activateRoute,router);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.activateRoute.params.subscribe(params=>{
      this.getNews(params);
    });


  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  adStyle(doc,css){
    var style = doc.createElement("style");
    style.type = "text/css";
    try{
      style.appendChild(document.createTextNode(css));

    }catch(ex){
      style['styleSheet'].cssText = css;//针对IE
    }
    var head = doc.getElementsByTagName("head")[0];
    head.appendChild(style);
  }


  async back(to:any=true):Promise<any>{
    this.backPage.emit(this);
    this.dispatch(ModuleComponent.Event.backPage,this.params);
    history.go(-1);
  }
  async getNews(params){
    console.log(params.id);
    
    let {data}=await Ajax.post('get-news-detail',{id:params.id});
    this.newItem=data;
    console.log(data);
    
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl((data.showreel.url));
    window.addEventListener('message',(e) => {
      let eve=e.data;
      if(eve.type==='bodyWh'){
        this.iframeStyle.height=eve.data.bodyHeight+100+'px';
      }
    })
  }
  onload($event){
    let iframe=$event.target;
    let doc=iframe.contentDocument || iframe.contentWindow.document;
  }
}
