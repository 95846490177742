import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-module6',
  templateUrl: './module6.component.html',
  styleUrls: ['./module6.component.css']
})
export class Module6Component extends ModuleComponent implements OnInit {


  ngOnInit(): void {
    super.ngOnInit();
  }

}
