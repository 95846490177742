<div class="page page-module3" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full transition " animation transitionDelay="0"><img src="./assets/images/news-bg.jpg"></div>
    </div>
    <div class="page-view">
        <h1 class="title transition">NEWS</h1>
        <ng-container *ngIf="!isBack">
            <div class="news-list flex h space-between animated fadeIn" *ngIf="loadingEnd">
                <div class="news-item " routerLink="/news-details/{{item.id}}" *ngFor="let item of newsList;let index=index;" [style]="{backgroundImage:'url('+(item.cover)+')'}">
                    <p class="subtitle" [innerHTML]="item.title"></p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
