<div class="page-menu" >
    <div class="page  " [class.scatter]="scatter">
        <p class="close" (click)="back()"  *ngIf="!isBack"><fa name="times"></fa></p>
        <div class="bg-layer">
            <div class="bg-layer-ele full  transition bg-full" animation transitionDelay="0"></div>
            <div class="bg-layer-ele full transition bg-01" animation transitionDelay="0"><img src="./assets/images/menu-layer2.png"></div>
            <div class="bg-layer-ele lt transition bg-02" animation transitionDelay="0"><img src="./assets/images/menu-layer1.png"></div>
            <div class="bg-layer-ele lb transition bg-03" animation transitionDelay="0"><img src="./assets/images/menu-layer3.png"></div>
            <div class="bg-layer-ele full transition">
                <div class="bg-layer-ele animated infinite pulse long  bg-04" animation transitionDelay="0"><img src="./assets/images/news-layer-image.png"></div>
            </div>
        </div>
        <p class="title animated" [class.zoomIn]="!scatter" [class.zoomOut]="scatter" ><img src="./assets/images/logo1-b.png"></p>
        <ul class="nav-menus">
            <li class="item-menu transition" *ngFor="let menu of menus; let index = index" animation [transitionDelay]="isBack?0:index*100+800" (click)="toMenu(menu)">
                <span [innerHTML]="menu.name"></span>
                <p class="arrow flex v main-center by-center">
                    <fa  name="angle-right"></fa>
                </p>
            </li>
            <li class="search flex h space-between transition" *ngIf="false">
                <fa name="search" class="icon"></fa>
                <input type="text" class="search-input">
            </li>
        </ul>
    </div>

</div>
