<div class="page page-module4" [class.scatter]="scatter">
    <div class="back" (click)="back()"  *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele transition full"><img src="./assets/images/ai-city-bg.jpg"></div>
        <div class="bg-layer-ele rt transition bg-01" animation transitionDelay="0"><img src="./assets/images/ai-city-layer1.png"></div>
        <div class="bg-layer-ele rt transition bg-02" animation transitionDelay="0"><img src="./assets/images/ai-city-layer2.png"></div>
        <div class="bg-layer-ele rb transition bg-03" animation transitionDelay="0"><img src="./assets/images/ai-city-layer3.png"></div>
    </div>
    <div class="title transition">AI CITY</div>
    <div class="version">
        <a routerLink="/index/bottom/details/3">
            <div class="version-item version-item4 transition"><img src="./assets/images/ai-city-module4.jpg"></div>
        </a>
        <a  routerLink="/index/bottom/details/0">
            <div class="version-item version-item1 transition"><img src="./assets/images/ai-city-module1.jpg"></div>
        </a>
        <a routerLink="/index/bottom/details/2">
            <div class="version-item version-item3 transition"><img src="./assets/images/ai-city-module3.jpg"></div>
        </a>
        <a  routerLink="/index/bottom/details/1">
            <div class="version-item version-item2 transition"><img src="./assets/images/ai-city-module2.jpg"></div>
        </a>
    </div>
</div>
<router-outlet></router-outlet>
