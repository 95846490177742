import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent extends ModuleComponent implements OnInit {


}
