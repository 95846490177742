import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent extends ModuleComponent implements OnInit {
  config={
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  slides=[
    {
      index:1,
      text:"Terminus Group AIoT Smart Office Building Solution is designed to create a pleasant office environment, by using AIoT, cloud computing and computer vision technologies to solve varied problems."
    },
    {
      index:2,
      text:"Terminus Group Smart City Solutions enable city IoT perception, city management, community service and other functions, serving as a smart brain and management decision platforms for cities"
    },
    {
      index:3,
      text:"Targeted at malls, smart retail, smart gyms, and smart parking lots, Terminus Group Smart Retail Solution adopts cutting-edge technologies like IoT, computer vision, smart access control endpoints"
    },
    {
      index:4,
      text:"Terminus Group Smart Hospital Solution offers a TOUCH KEY-centered, patient-oriented, intelligent and digitalized management platform. This matrix approach to management, control, intelligence and prevention is designed to enable a faster transformation to smart, digital hospitals."
    },
    {
      index:5,
      text:"Terminus Group Smart Campus Solution is created to ensure maximum safety of campus, improve the utilization of education resources and better manage school assets by integrating information from various facilities automatically coordinated by Terminus Group cloud platform, including access control, monitoring, lifts and energy systems."
    },
    {
      index:6,
      text:"The Terminus Group Smart Parking Solution is designed to enable real \"unmanned\" management of parking lots with highly integrated AI-enabled plate recognition and vehicle management system, CCTV-based track monitoring system, remote visual intercom system, alarm system and centralized control system."
    },
    {
      index:7,
      text:"This integrated system provides comprehensive management for cities, including device measurement and analysis, information gathering/processing/storage/submission and online monitoring/control of pollutant sources (waste water/exhaust gas/heavy metal/noises/radiation, etc.)"
    },
    {
      index:8,
      text:"Terminus Group Smart Tourism Solution not only improves tourist experiences, but also makes the resort management smarter, more efficient and more automated. Meanwhile, the circulation of capital and information in tourism is accelerated by Smart Access, Senseless Payment and Information Channel Management, which in turn drives the development of whole industry."
    }
  ];
}
