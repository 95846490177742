const apiConfig={
    /**
     * 获取html
     */
    'get-html':{
        api:"/file/get-html"
    },
    'put-html':{
        api:"/file/upload-html"
    },
    /**
     * 获取新闻
     */
    'get-news': {
        api: '/GetNews'
    },
    'get-blog':{
        api: '/GetBlogs'
    },
    'get-news-detail':{
        api:'/GetDetail'
    },
    'get-search':{
        api: '/GetSearch'
    },
}
export default apiConfig;
