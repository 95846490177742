<div class="page page-terminus-details" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Solution </p>
            <p class="big"></p>
        </div>
        <div  class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of slides" [routerLink]="'/index/solutions/details-'+item.index">
                    <p> <img src="./assets/images/solutions-image{{item.index}}.jpg"></p>
                    <p class="desc">
                        Targeted at malls, smart retail, smart gyms, and smart parking lots, Terminus Group Smart Retail Solution adopts cutting-edge technologies like IoT, computer vision, smart access control endpoints
                    </p>
                </div>
            </div>
        </div>
        <div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false"></div>
        <div class="swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-disabled="true"></div>
    </div>
</div>
<router-outlet></router-outlet>
