import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'mobile-intelligence';
  ngOnInit(): void {
    document.documentElement.style.fontSize=`${window.innerWidth/375*20}px`;
  }
}
