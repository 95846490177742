import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-us',
  templateUrl: './us.component.html',
  styleUrls: ['./us.component.css']
})
export class UsComponent extends ModuleComponent implements OnInit {

}
