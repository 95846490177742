import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-terminus-details',
  templateUrl: './terminus-details.component.html',
  styleUrls: ['./terminus-details.component.css']
})
export class TerminusDetailsComponent extends ModuleComponent implements OnInit {
  config={
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : true,
    },
  };
  index=1;
  slides=[
    {
      index:2,
      path:"/index/left/list/details/2"
    },
    {
      index:1,
      path:"/index/left/list/details/1"
    },
    {
      index:3,
      path:"/index/left/list/details/3"
    }
  ];
}
