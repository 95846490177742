<div class="page page-us" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full bg-white transition" animation transitionDelay="0"></div>
        <div class="bg-layer-ele lt transition bg-01" animation transitionDelay="0"><img src="./assets/images/us-layer1.png"></div>
        <div class="bg-layer-ele full transition bg-03" animation transitionDelay="0"><img src="./assets/images/us-layer3.png"></div>
        <div class="bg-layer-ele lb transition bg-02" animation transitionDelay="0"><img src="./assets/images/us-layer2.png"></div>
        <div class="bg-layer-ele rb transition bg-04" animation transitionDelay="0"><img src="./assets/images/us-layer4.png"></div>
    </div>
    <div class="text-contents">
        <p class="logo animated" [class.zoomIn]="!scatter" [class.zoomOut]="scatter" ><img src="./assets/images/logo1-b.png"></p>
        <h1 class="title animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation [animationDelay]="!isBack?1000:0">
            <img src="./assets/images/blog-image.png">
        </h1>
        <div class="more-info animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation  [animationDelay]="!isBack?1300:0">
            <h1 class="subtitle">AI CITY: a step forward <br/>to turning future vision into reality</h1>
            <p class="desc">
                <b>Need of AI CITY under the pandemic</b><br/>
                Each lasting chaotic disaster nowadays reminds us of considering about city management that matters a lot when facing a crisis. Absolutely, city management is also an important section in AI CITY construction. An AI CITY is whether citizens can rely on the Internet to enjoy public services and infrastructure to the fullest extent, rather than doing some imagery projects that are only suitable for leaders to investigate. AI CITY is conducive to the transformation of local government management functions. In addition, the benefits of big data and cloud computing are to link the originally scattered different information islands and improve efficiency. Terminus Group AI CITY solution is finding every way to take the lead of AI CITY establishment tide and turning future vision into the real world.
            </p>
        </div>
    </div>
</div>
