import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-module4',
  templateUrl: './module4.component.html',
  styleUrls: ['./module4.component.css']
})
export class Module4Component extends ModuleComponent implements OnInit {
}
