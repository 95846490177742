import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-bg-audio',
  templateUrl: './bg-audio.component.html',
  styleUrls: ['./bg-audio.component.css']
})
export class BgAudioComponent implements OnInit {
  isPlay=false;
  constructor(private el:ElementRef) { }

  ngOnInit(): void {
    this.playAudio();
  }
  playAudio(){
    var audio:any=window['_audio'];
    if(!audio)return;
    audio.play();
    this.isPlay=!audio.paused;
    audio.addEventListener('play',()=>{
      this.isPlay=true;
    },false)
    audio.addEventListener('pause',()=>{
      this.isPlay=false;
    },false)
    audio.addEventListener('canplay',()=>{
      audio.play();
    },false);
  }

  togglePlay(){
    var audio:any=window['_audio'];
    if(!audio)return;
    if(!audio.paused){
      audio.pause();
    }else{
      audio.play();
    }
  }

}
