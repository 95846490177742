<div class="page page-terminus-list" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="title top transition">TERMINUS GROUP</div>
    <div class="column">
        <div class="column-item transition top" routerLink="/index/left/list/details">
            <img src="./assets/images/terminus-list-image1.jpg">
            <div class="guide bounceIn animated infinite long" style="left: auto;right: 0;top: auto;bottom: 0">
                <img src="./assets/images/guide.png">
            </div>
        </div>
        <div class="column-item transition bottom" routerLink="/index/left/list/people">
            <img src="./assets/images/terminus-list-image2.jpg">
            <div class="guide bounceIn animated infinite long" style="left: auto;right: 0;top: 0">
                <img src="./assets/images/guide.png">
            </div>
        </div>
    </div>
    <div class="title bottom transition">TERMINUS PEOPLE</div>

</div>
<router-outlet></router-outlet>
