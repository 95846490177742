import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-huo-lei',
  templateUrl: './huo-lei.component.html',
  styleUrls: ['./huo-lei.component.css']
})
export class HuoLeiComponent extends ModuleComponent implements  OnInit {

}
