<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Retail<br/>Solution</p>
            <p class="big"></p>
        </div>
        <p class="desc">
            Targeted at malls, smart retail, smart gyms, and smart parking lots, Terminus Group Smart Retail Solution adopts cutting-edge technologies like IoT, computer vision, smart access control endpoints in industry-leading APPs and Smart Retail Management Platform, so as to enable smart business with integrated consumer behavior and transaction data and more accurate user profiling.
        </p>
        <p class="image">
            <img src="./assets/images/terminus-details-content-image1.jpg">
        </p>
    </div>
</div>
