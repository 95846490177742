import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-terminus-details-content8',
  templateUrl: './terminus-details-content8.component.html',
  styleUrls: ['./terminus-details-content8.component.css']
})
export class TerminusDetailsContent8Component extends ModuleComponent  implements OnInit {


}
