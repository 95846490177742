import { Component, OnInit} from '@angular/core';
import { ModuleComponent } from '../../class/ModuleComponent';
import Ajax from "../../public/Ajax";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends ModuleComponent implements OnInit {
  loadingEnd = false;
  searchList: any[] = [];
  id;
  ngOnInit(): void {
    super.ngOnInit();
    var url = window.location.href;
    var n1 = url.length;//地址的总长度
    var n2 = url.indexOf("=");//取得=号的位置
    var id = url.substr(n2 + 1, n1 - n2);//从=号后面的内容 
    this.id = id;
    setTimeout(e => this.getRecommendedNews(), 1000);
  }
  async getRecommendedNews() {
    let {data}=await Ajax.post('get-search',{key_word:this.id}); 
    this.searchList=data.showreel||[];
    console.log(this.searchList)
    this.loadingEnd=true;
  }

}
