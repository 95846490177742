import {Component, Input, OnInit} from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import {ActivatedRoute} from '@angular/router';
import {SwiperConfig} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-module4-details',
  templateUrl: './module4-details.component.html',
  styleUrls: ['./module4-details.component.css']
})
export class Module4DetailsComponent extends ModuleComponent implements OnInit {
  config={
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : true,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };
  currentData=null;
  private _index=1;
  @Input() set index(value){
    this._index=value;
    this.currentData=this.data[value];
  };
  get index(){
    return this._index;
  }
  data=[
    {
      title:"AI CITY",
      subtitle:"Standalone AI Smart Products.",
      texts:"This phase used individual AIoT-powered smart products to handle specific tasks or events. This could be in the form of internet connected sensors, robots, or  wearables."
    },
    {
      title:"AI CITY",
      subtitle:"Bespoke AIoT Solutions",
      texts:"This phase is solution-driven.  Instead of using a singular product, we use a combination of our AIoT-powered products to tailor a solution to the needs of our customers."
    },
    {
      title:"AI CITY",
      subtitle:"Terminus' AI CITY",
      texts:"This phase scales our AIoT solutions to entire cities. By innovating across several areas, we are able to create a comprehensive matrix to power our AI CITY."
    },
    {
      title:"AI CITY",
      subtitle:" AI CITY Grids",
      texts:"This is the final phase of Terminus’ AI CITY Roadmap. Imagine a world where granular data can flow quickly and efficiently across borders to allow for meaningful cultural exchanges and diffusion of information in times of crisis. "
    }
  ];
  ngOnInit(): void {
    super.ngOnInit();
    this.activateRoute.params.subscribe(({index})=>this.index=index);
  }
}
