<div class="page page-module2" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full"></div>
        <div class="bg-layer-ele lt transition bg-01" animation transitionDelay="0"><img src="./assets/images/about-layer1.png"></div>
        <div class="bg-layer-ele lt transition bg-02" animation transitionDelay="0"><img src="./assets/images/about-layer2.png"></div>
        <div class="bg-layer-ele lt transition bg-03" animation transitionDelay="0"><img src="./assets/images/about-layer3.png"></div>
        <div class="bg-layer-ele lb transition bg-04"><img src="./assets/images/about-layer4.png"></div>
    </div>
    <div class="title transition">
        <p class="small">About </p>
        <p class="big">Terminus</p>
    </div>
    <div class="rect-bg transition" animation (transitionEnd)="animatedEnd=true">
        <img src="./assets/images/module-2-big.png">
    </div>
    <div class="main-image animated fadeIn" *ngIf="animatedEnd&&!isBack" >
        <object  data="./assets/images/module-2-image1.svg" type="image/svg+xml"></object>
    </div>
    <div class="text-content transition flex v main-center" animation [transitionDelay]="isBack?0:1600" transitionDuration="500">
        <div class="text-bg"><img src="./assets/images/about-text-bg.png"></div>
        <p class="texts">Terminus is a leading AI smart service provider, shaping the next generation of technology with Artificial Intelligence & Internet of Things (AIoT).</p>
        <p class="see" routerLink="/index/left/list"><img src="./assets/images/see-details.png"></p>
        <p class="arrow">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21.9px" height="48px" viewBox="0 0 21.9 48" style="enable-background:new 0 0 21.9 48;" xml:space="preserve">
                <style type="text/css">
                    .st0{fill:#000097;}
                </style>
                <defs>
                </defs>
                 <polygon class="st0" points="0,24 21.9,48 21.9,0 "/>
            </svg>
        </p>
    </div>
    <div class="huolei-button transition" routerLink="/index/left/huolei" *ngIf="false">Huolei lnitiative</div>
</div>
<router-outlet></router-outlet>
