<div class="flex main-center by-center loading-page">
    <div>
        <div class="loading">
            <div>
                <span></span>
            </div>
            <div>
                <span></span>
            </div>
            <div>
                <span></span>
            </div>
        </div>
        <p class="text">资源加载中({{percentage}}%)....</p>
    </div>
</div>
