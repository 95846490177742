import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends ModuleComponent implements OnInit {
  menus=[
    {
      name:"AI CITY",
      link:"/index/bottom"
    },
    {
      name:"Solutions",
      link:"index/solutions"
    },
    {
      name:"Product",
      link:"/index/right"
    },
    {
      name:"About Terminus",
      link:"/index/left"
    },
    {
      name:"News",
      link:"/index/top"
    },
    {
      name:"Blog",
      link:"/index/blog"
    },
    // {
    //   name:"Huolei Initiative",
    //   link:"/index/huolei"
    // },
    {
      name:"Contact Us",
      link:"/index/us"
    },
  ];
  async back(to:any=true):Promise<any>{
    this.scatter = true;
    this.backPage.emit(this);
    setTimeout(e=>{
      this.leavePage.emit(this);
    },1000);
  }
  ngOnInit(): void {
    //重写
    requestAnimationFrame(e=>{this.scatter=false;});
  }

  ngOnDestroy() {
    //重写
  }
  toMenu(menu){
    this.router.navigate([menu.link]);
    this.back();
  }
}
