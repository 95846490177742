import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-terminus-list',
  templateUrl: './terminus-list.component.html',
  styleUrls: ['./terminus-list.component.css']
})
export class TerminusListComponent extends ModuleComponent  implements OnInit {

}
