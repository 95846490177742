<div class="header gl-padding flex h space-between by-center">
    <p class="logo" (click)="toIndex()"><img src="./assets/images/logo.svg"></p>
    <div class="input_group">
        <input class="header_input" type="text" placeholder="search" [value]="firstname" (input) = "firstname = $event.target.value"  >
        <button class="header_btn fa fa-search" (click)="toSearch()"></button>
    </div>
    <p class="menu" (click)="display()" >
        <fa name="bars"></fa>
    </p>
</div>
<app-menu *ngIf="showMenu" (leavePage)="leaveModule()"></app-menu>
