import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-terminus-details-content2',
  templateUrl: './terminus-details-content2.component.html',
  styleUrls: ['./terminus-details-content2.component.css']
})
export class TerminusDetailsContent2Component extends ModuleComponent implements OnInit {

}
