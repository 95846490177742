<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0">
            <img src="./assets/images/terminus-details-bg2.jpg">
        </div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Community <br/>Solution</p>
            <p class="big"></p>
        </div>
        <div class="text-block">
            <p class="subtitle">SMART COMMUNITY</p>
            <p class="desc">
                Terminus Group Smart City Solutions enable city IoT perception, city management, community service and other functions, raise the intelligent level of government supervision, service and decision-making, and develop new management models of high effciency and convenience, serving as a smart brain and management decision platforms for cities.
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">SMART SENSING PLATFORM</p>
            <p class="desc">
                Community security is now smarter with Terminus Group's industry-leading access control technologies, which not only improves the efficiency of social management, but also gives citizens greater peace of mind. Moreover, smoke detectors and firefighting equipment in in-building public areas and rooms are also connected to enable convenient access to their real-time working status. This will help communities to eliminate fire hazards.
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">Refined Societal Governance</p>
            <p class="desc">
                • Dynamic and static community management<br/>
                • Building Safe Cities By Supporting "Public Security Pilots"<br/>
                • Improving Societal Governance Building Smart Cities
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">CUSTOMER VALUE</p>
            <p class="desc">
                • People-Centered Unified Sensing<br/>
                • Improved community security services<br/>
                • Better community service efficiency
            </p>
            <p class="image">
                <img src="./assets/images/terminus-details-content-image3.jpg" alt="">
            </p>
        </div>
    </div>
</div>
