import { Component, OnInit,Input } from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {ModuleComponent} from '../../class/ModuleComponent';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  menuIn=true;
  firstname="";
  @Input() outletcomponent:RouterOutlet;
  constructor(public router:Router,public activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
  }
  async toIndex(){
    if(this.router.url==='/index')return ;
    if(/^\/news\-details/.test(this.router.url)){
      this.router.navigate(['/index']);
      return ;
    }
    let {component}=this.outletcomponent;
    let _component:ModuleComponent=<any>component;
    let getCurrentRouter=router=>{
      if(router.firstChild){
        return getCurrentRouter(router.firstChild);
      }else {
        return router;
      }
    }
    let currentActiveRouter=getCurrentRouter(_component.activateRoute);
    currentActiveRouter.data.next({name:ModuleComponent.Event.enterPage,params:{}});
    (<BehaviorSubject<any>>this.activatedRoute.data).next({name:ModuleComponent.Event.backPage,params:{}});
    setTimeout(e=>this.router.navigate(['/index']),1000);
  }
  display(){
    this.showMenu=true;
  }

  leaveModule(){
    this.showMenu=false;
  }
  toSearch(){
    this.router.navigate(['/index/search'],{
      queryParams: {
        value: this.firstname
      }
}
    );
  }
}
