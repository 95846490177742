<div class="page page-us" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full bg-white transition" animation transitionDelay="0"></div>
        <div class="bg-layer-ele lt transition bg-01" animation transitionDelay="0"><img src="./assets/images/us-layer1.png"></div>
        <div class="bg-layer-ele full transition bg-03" animation transitionDelay="0"><img src="./assets/images/us-layer3.png"></div>
        <div class="bg-layer-ele lb transition bg-02" animation transitionDelay="0"><img src="./assets/images/us-layer2.png"></div>
        <div class="bg-layer-ele rb transition bg-04" animation transitionDelay="0"><img src="./assets/images/us-layer4.png"></div>
    </div>
    <div class="text-contents">
        <p class="logo animated" [class.zoomIn]="!scatter" [class.zoomOut]="scatter" ><img src="./assets/images/logo1-b.png"></p>
        <div class="blog_list" *ngFor="let item of BlogsList;let index=index;" style="margin-bottom: 1rem;">
            <h1 class="title animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation [animationDelay]="!isBack?1000:0">
                <img [src]="item.cover">
            </h1>
            <div class="more-info animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation  [animationDelay]="!isBack?1300:0">
                <h1 class="subtitle" [innerHTML]="item.title"></h1>
                <p class="desc" [innerHTML]="item.desc">
                </p>
                <div class="more" routerLink="/news-details/{{item.id}}">More</div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
