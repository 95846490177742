import {EventEmitter, Input, OnInit, Output, ViewChild,AfterViewInit} from '@angular/core';
import {ActivatedRoute, Data, Router, RouterOutlet} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';

export class ModuleComponent implements OnInit,AfterViewInit{
    static Event={
        enterPage:"enterPage",
        backPage:"backPage",
        leavePage:"leavePage"
    };
    @Output() enterPage=new EventEmitter();
    @Output() backPage=new EventEmitter();
    @Output() leavePage=new EventEmitter();
    pageHidden:boolean=false;
    private _scatter:boolean=true;
    set scatter(value){
        this._scatter=value;
        if(value){
            setTimeout(e=>{
                this.pageHidden=true;
            },1000);
        }else {
            this.pageHidden=false;
        }
    }
    get scatter(){
        return this._scatter;
    }
    get isBack():boolean{
        return this.scatter;
    }
    dataInteraction:Subject<Data> =new Subject<Data>();
    params:Data={};
    private _rect:DOMRect=null;
    @Input() set rect(value){
       this._rect=value;
       this.initModule();
    };
    get rect():DOMRect{
        return this._rect;
    }
    bgStyles:any={};
    private router_params_subscribe=null;
    private router_data_subscribe=null;
    constructor(public activateRoute:ActivatedRoute,public router:Router) {
    }

    dispatch(name,params){
        let parentActivateRouter=this.activateRoute.parent;
        let data:BehaviorSubject<Data>=<any>parentActivateRouter.data;
        data.next({name,params});
    }

    ngOnInit(): void {
        this.enterPage.emit(this);
        requestAnimationFrame(e=>{this.scatter=false;});
        this.router_params_subscribe=<any>this.activateRoute.params.subscribe(params=>{
            this.params=params;
            setTimeout(e=>{ this.dispatch(ModuleComponent.Event.enterPage,this.params);})
        });

        let {enterPage,backPage,leavePage}=ModuleComponent.Event;
        this.router_data_subscribe=<any>this.activateRoute.data.subscribe(e=>{
            switch (e.name) {
                case enterPage:
                    let {params}=e;
                    this.enterModule();
                    break;
                case backPage:
                    this.backModule();
                    break;
                case leavePage:
                    this.leaveModule();
                    break;

            }
        })
        // window.scrollTo({
        //     left: 0,
        //     top: 0,
        //     behavior: 'smooth'
        // })
        window.scrollTo(0,0);
    }
    initModule(){
        if(this.rect){
            let {left,top,width}=this.rect;
            this.bgStyles={
                left,
                top,
                width
            };
            Object.keys(this.bgStyles).forEach(key=>{
                this.bgStyles[key]=`${this.bgStyles[key]}px`;
            })
        }
    }
    async back(to:any=true):Promise<any>{
        this.scatter=true;
        this.backPage.emit(this);
        this.dispatch(ModuleComponent.Event.backPage,this.params);
        return new Promise(((resolve, reject) => {
            setTimeout(e=>{
                if(to===true)history.go(-1);
                if(typeof to==='string'){
                    this.router.navigate([to]);
                }
                //window.scrollTo(0,0);
                resolve();
            },1000);
        }))
    }

    backModule(){
        this.scatter=false;
    }
    leaveModule(){
        this.scatter=false;
    }
    enterModule(){
        this.scatter=true;
    }

    ngOnDestroy(){
        console.log('ngOnDestroy')
        this.leavePage.emit(this);
        this.dispatch(ModuleComponent.Event.leavePage,this.params);
        this.router_params_subscribe.unsubscribe();
    }

    ngAfterViewInit(){
    }
}
