<app-header [outletcomponent]="outletcomponent"></app-header>
<div class="page-index page">
    <div class="bg-layer" [class.scatter]="scatter">
        <div class="bg-layer-ele lt transition bg-01">
            <img src="./assets/images/index-layer1.png">
        </div>
        <div class="bg-layer-ele lt transition bg-02" animation transitionDelay="300">
            <img src="./assets/images/index-layer2.png">
        </div>
        <div class="bg-layer-ele lb transition bg-03">
            <img src="./assets/images/index-layer3.png">
        </div>
        <div class="bg-layer-ele rb transition bg-04">
            <img src="./assets/images/index-layer4.png">
        </div>
    </div>
    <div class="contactUs " [class.expand]="expand" style="background: url(./assets/images/packUp.png) no-repeat center;background-size: 100%">
        <div class="over transition">
            <div class="slide" style="background: url(./assets/images/expand.png) no-repeat center;background-size: 100%">
                <a class="a" href="https://twitter.com/GroupTerminus"></a>
                <a class="a" href="mailto:contact@tslsmart.com"></a>
                <a class="a" href="tel:0086 400-6299-000"></a>
            </div>
            <div class="expand" (click)="expand=false"></div>
        </div>
        <div class="packUp" (click)="expand=true"></div>
    </div>
    <app-contents [class.scatter]="scatter">
        <div class="module box">
            <div class="center module">
                <a routerLink="/index/center">
                    <div class="module-center" [class.transition]="nowModule!='center'">
                        <img src="./assets/images/module-6.png">
                        <div class="alternate guide fadeIn animated infinite long"  *ngIf="dotIndex===4">
                            <img src="./assets/images/guide.png">
                        </div>
                    </div>
                </a>
                <a routerLink="/index/top">
                    <div class="module transition top" [class.transition]="nowModule!='top'">
                        <img src="./assets/images/module-3.png">
                        <div class="alternate guide fadeIn animated infinite long"  *ngIf="dotIndex===1">
                            <img src="./assets/images/guide.png">
                        </div>
                    </div>
                </a>
                <a routerLink="/index/left">
                    <div class="module transition left" [class.transition]="nowModule!='left'">
                        <img src="./assets/images/module-2.png">
                        <div class="alternate guide fadeIn animated infinite long" *ngIf="dotIndex===0">
                            <img src="./assets/images/guide.png">
                        </div>
                    </div>
                </a>
                <a routerLink="/index/right">
                    <div class="module transition right" [class.transition]="nowModule!='right'">
                        <img src="./assets/images/module-5.png">
                        <div class="alternate guide fadeIn animated infinite long"  *ngIf="dotIndex===2">
                            <img src="./assets/images/guide.png">
                        </div>
                    </div>
                </a>
                <a routerLink="/index/bottom">
                    <div class="module transition bottom" [class.transition]="nowModule!='bottom'">
                        <img src="./assets/images/module-4.png">
                        <div class="guide  fadeIn animated infinite alternate long"  *ngIf="dotIndex===3">
                            <img src="./assets/images/guide.png">
                        </div>
                    </div>
                </a>
                <div class="module transition side" [class.transition]="nowModule!='slide'">
                    <ng-container *ngFor="let index of [1,2,3]">
                        <img src="./assets/images/module-1-top{{index}}.jpg" class="slide-img animated  long" [class.fadeIn]="currentIndex===index" [class.fadeOut]="currentIndex!==index" animation>
                    </ng-container>
                    <p class="side-title">
                        <span class="small">we are</span>
                        <br>Terminus Group
                    </p>
                    <div class="circle">
                        <div class="circle-ring animated rotation-dystopia">
                            <img src="./assets/images/module-1-circle.png">
                        </div>
                        <div class="circle-content animated rotation-is">
                            <img src="./assets/images/module-1-circle-content.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="line" >
                <!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <object  data="./assets/images/index-line.svg" type="image/svg+xml"></object>
            </div>
        </div>
    </app-contents>
</div>
<div class="subpage">
    <router-outlet #outlet></router-outlet>
</div>
<app-bg-audio></app-bg-audio>
<!--<app-module2 *ngIf="nowModule==='left'" (backPage)="backModule($event)"  (leavePage)="leaveModule($event)"></app-module2>
<app-module3 *ngIf="nowModule==='top'" (backPage)="backModule($event)"  (leavePage)="leaveModule($event)"></app-module3>
<app-module4 *ngIf="nowModule==='bottom'" (backPage)="backModule($event)"  (leavePage)="leaveModule($event)"></app-module4>
<app-module6 *ngIf="nowModule==='center'" (backPage)="backModule($event)"  (leavePage)="leaveModule($event)"></app-module6>-->
