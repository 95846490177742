import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-terminus-details-content5',
  templateUrl: './terminus-details-content5.component.html',
  styleUrls: ['./terminus-details-content5.component.css']
})
export class TerminusDetailsContent5Component  extends ModuleComponent  implements OnInit {


}
