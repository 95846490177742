import { Component, OnInit,ViewChild } from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {ModuleComponent} from '../../class/ModuleComponent';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent extends ModuleComponent{
  @ViewChild(RouterOutlet) outletcomponent:RouterOutlet;
  scatter:boolean=true;
  nowModule:string=null;
  expand=false;
  currentModuleRect:DOMRect=null;
  swiperConfig= {
    effect: 'flip',
    grabCursor: true,
    loop:true
  };
  currentTimer=null;

  private _currentIndex=1;
  get currentIndex(){
    return this._currentIndex;
  };
  set currentIndex(index){
    if(index>3){
      index=1;
    }
    if(index<1){
      index=1;
    }
    if(this.currentTimer)clearTimeout(this.currentTimer);
    this.currentTimer=setTimeout(e=>this.currentIndex++,5000)
    this._currentIndex = index;
  }

  private currentDotTimer=null;
  private _dotIndex=0;
  get dotIndex(){
    return this._dotIndex;
  };
  set dotIndex(index){
    if(index>4){
      index=0;
    }
    if(this.currentDotTimer)clearTimeout(this.currentDotTimer);
    this.currentDotTimer=setTimeout(e=>this.dotIndex++,4000)
    this._dotIndex = index;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currentIndex++;
    this.dotIndex=0;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    clearTimeout(this.currentTimer);
    clearTimeout(this.currentDotTimer);
  }
}
