<div class="page page-module3" [class.scatter]="scatter">
    <div class="back" (click)="back()"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full transition " animation transitionDelay="0"><img src="./assets/images/news-bg.jpg"></div>
    </div>
    <div class="page-view" style="padding-top: 3rem;">
        <h1 class="title transition" style="color: #000;
        position: relative;
        z-index: 1;">Search</h1>
        <div class="news-list flex h space-between animated fadeIn">
            <div class="news-item" routerLink="/news-details/{{item.id}}" *ngFor="let item of searchList;let index=index;" [style]="{backgroundImage:'url('+(item.thumb)+')'}">
                <p class="subtitle" [innerHTML]="item.title"></p>
            </div>
        </div>
    </div>
</div>
