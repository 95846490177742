import { Component, OnInit } from '@angular/core';
import {ModuleComponent} from "../../class/ModuleComponent";
import {ActivatedRoute} from '@angular/router';
import {IndexComponent} from '../index/index.component';
@Component({
  selector: 'app-module2',
  templateUrl: './module2.component.html',
  styleUrls: ['./module2.component.css']
})
export class Module2Component extends ModuleComponent implements OnInit {
  animatedEnd=false
}
