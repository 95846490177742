import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
const images=`solutions-image1.jpg
solutions-image2.jpg
solutions-image6.jpg
solutions-image3.jpg
solutions-image7.jpg
solutions-image4.jpg
solutions-image8.jpg
solutions-image5.jpg
boss.jpg
terminus-list-image1.jpg
terminus-list-image2.jpg
blog-image.png
see-details.png
module-6.png
guide.png
guide.gif
module-2-image1.svg
module-5.png
menu-layer3.png
us-layer3.png
module5_product3_1.png
module5_product3_2.png
terminus-details-image2.jpg
huolei-bg.jpg
module-1-top1.jpg
module5_product2.png
module-1-top2.jpg
module5_product1_2.png
terminus-details-bg2.jpg
module5_product1_1.png
module-5-big.png
module-1-top3.jpg
terminus-details-content-image1.jpg
module4-details-bg.jpg
ai-city-bg.jpg
terminus-details-bg.jpg
module-6-big-ball.png
terminus-details-image1.jpg
us-layer4.png
module-6-big.png
index-line.svg
module-3.png
huolei-layer3.png
huolei-layer2.png
huolei-layer1.png
terminus-details-content-image3.jpg
terminus-details-content-image2.jpg
terminus-details-image3.jpg
terminus-details-layer3.png
terminus-details-layer2.png
terminus-details-layer1.png
us-icon-email.png
us-icon-phone.png
us-layer2.png
us-layer1.png
ai-city-module3.jpg
index-line.png
news-layer-image.png
news-bg.jpg
module5_product1_3.png
module-5-layer2.png
module-2-big.png
module-1.png
module5_product2_eye.png
module5_product1_3_eye.png
module-5-layer5.png
back-icon-w.png
module-5-layer4.png
module-5-layer1.png
module-5-layer3.png
menu-layer2.png
menu-layer1.png
module-1-circle-content.png
module-1-circle.png
vision-layer3.png
module4-details4-80.jpg
module4-details3-80.jpg
module4-details2-80.jpg
module4-details1-80.jpg
module2-layer.png
module-4.png
module-2.png
module-2-image1.png
module-1.jpg
menu-bg.jpg
angle.png
ai-city-module4.jpg
ai-city-module2.jpg
about-layer4.png
vision-text-bg.png
vision-layer1.png
ai-city-module1.jpg
ai-city-layer3.png
ai-city-layer1.png
ai-city-layer2.png
back-icon.png
about-text-bg.png
about-layer1.png
about-layer2.png
about-layer3.png
index-layer4.png
index-layer2.png
index-layer1.png
index-layer3.png
title.svg
header-bg.jpg
menu.svg
logo.svg`;
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  images=[];
  loadIndex=0;
  percentage=0;
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.images=images.split("\n").map(file=>`/assets/images/${file}`)
    //this.router.navigate(['/index']);
    this.images.forEach((url,index)=>{
      let image=new Image();
      image.onload=e=>this.complete();
      image.onerror=e=>this.complete();
      image.src=url;
    })
  }
  complete(){
    this.loadIndex++;
    this.percentage=Math.ceil(+((this.loadIndex/this.images.length).toFixed(2))*100);
    console.log( this.percentage,' this.percentage')
    if(this.loadIndex===this.images.length){
      console.log('加载完成');
      window['__loading']=true;
      this.router.navigate(['/index']);
    }
  }

}
