<div class="page page-us" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full bg-white transition" animation transitionDelay="0"></div>
        <div class="bg-layer-ele lt transition bg-01" animation transitionDelay="0"><img src="./assets/images/us-layer1.png"></div>
        <div class="bg-layer-ele full transition bg-03" animation transitionDelay="0"><img src="./assets/images/us-layer3.png"></div>
        <div class="bg-layer-ele lb transition bg-02" animation transitionDelay="0"><img src="./assets/images/us-layer2.png"></div>
        <div class="bg-layer-ele rb transition bg-04" animation transitionDelay="0"><img src="./assets/images/us-layer4.png"></div>
    </div>
    <div class="text-contents">
        <p class="logo animated" [class.zoomIn]="!scatter" [class.zoomOut]="scatter" ><img src="./assets/images/logo1-b.png"></p>
        <h1 class="title animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation [animationDelay]="!isBack?1000:0">Contact Us</h1>
        <p class="desc animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation [animationDelay]="!isBack?1300:0">
            Please provide your contact information in the right column. We will arrange AI smart service EXPERT to get in touch with you as soon as possible to help you find the suitable AI products or solutions that meet your needs and goals.<br/>
            <br/>
            If you have any questions, please contact our AI EXPERT, by sending email to contact@tslsmart.com or calling +400-6299-000.
        </p>
        <div class="contact animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation [animationDelay]="!isBack?1600:0">
            <p class="contact-item flex h by-center" style="background-image: url(./assets/images/us-icon-phone.png)">
                <a href="tel:0086 400-6299-000">0086 400-6299-000</a>
            </p>
            <p class="contact-item flex h by-center" style="background-image: url(./assets/images/us-icon-email.png)">
                <a href="mailto:contact@tslsmart.com">contact@tslsmart.com</a>
            </p>
        </div>
        <div class="more-info animated" [class.fadeInUp]="!scatter" [class.fadeOutDown]="scatter" animation  [animationDelay]="!isBack?1900:0">
            <h1 class="subtitle">Beijing</h1>
            <p class="for-record">TERMINUS Group Co., Ltd.</p>
            <p class="desc">
                11F, West Tower, Genesis Beijing, South Xinyuan Road, Chaoyang District, Beijing<br/>
                Tel:010-85240200
            </p>
        </div>
    </div>
</div>
