<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Campus <br/>Solution</p>
            <p class="big"></p>
        </div>
        <p class="desc">
            Terminus Group Smart Campus Solution is created to ensure maximum safety of campus, improve the utilization of education resources and better manage school assets by integrating information from various facilities automatically coordinated by Terminus Group cloud platform, including access control, monitoring, lifts and energy systems.
        </p>
        <p class="image">
            <img src="./assets/images/terminus-details-content-image5.jpg">
        </p>
    </div>
</div>
