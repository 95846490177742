import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
import Ajax from "../../public/Ajax";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent extends ModuleComponent implements OnInit {
  loadingEnd=false;
  BlogsList:any[]=[];
  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(e=>this.getRecommendedNews(),1000);
  }
  async getRecommendedNews(){
    let {data}=await Ajax.post('get-news',{pageIndex:1,pageSize:100000});
    this.BlogsList=data.showreel||[];
    console.log(this.BlogsList)
    this.loadingEnd=true;
  }
}
