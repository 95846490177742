import axios from 'axios';
import apiConfig from './ApiConfig';

var process;
let isTest=false;
// if('development'===process.env.NODE_ENV){
//     isTest=true;
// }
//todo baseurl修改地址 对应的http代理文件proxy.config.json
var ajax = axios.create({
    baseURL: isTest?'https://www.terminusgroup.com/openapi/Tsl':'https://www.terminusgroup.com/openapi/Tsl',
    timeout: 1000*60,
    method:"post",
});
// 添加请求拦截器
ajax.interceptors.request.use( function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
ajax.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let result=response.data;
    if(result.status==401){
        //browserHistory.replace('/');
        return  Promise.reject(result);
    }
    if(result.status!=1)return Promise.reject(result);
    //console.log(result,'result')
    return result;
}, function (error) {
    console.log(error,'error')
    // 对响应错误做点什么
    return Promise.reject({status:-1,data:error});
});
const _ajax={
    async post(api:string,data:any={},config:any={}){
        console.log(data);
        
        let _apiconfig=apiConfig[api];
        if(!_apiconfig)return  Promise.reject(`${api} 不存在`);
        return  await ajax.post(_apiconfig.api,data,config);
    }
};
export default _ajax;
