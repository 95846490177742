<div class="page page-huolei" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon-w.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0">
            <img src="./assets/images/huolei-bg.jpg">
        </div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/huolei-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/huolei-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/huolei-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
       <div class="text-block">
           <h1 class="title"> Huolei Initiative</h1>
           <p class="subtitle">
               A Campaign to Contaiin<br/>
               the Covid-19 Epidemic
           </p>
           <p class="desc">
               "When I saw the courage with which my compatriots in Wuhan faced COVID-19, the challenge of their lives, I knew that Terminus Group could not sit this out. Inspired by their efforts, the Terminus community took action to invent technology, to mobilize resources and to help people. We are proud to have played a small role in supporting China during these dangerous times. Today, we stand ready to be of assistance wherever and whenever we are called. We are the Huolei Initiative."<br/>
               <br/>
           </p>
           <p class="desc text-right">-Victor Ai, Founder and CEO, Terminus Group</p>
       </div>
        <div class="text-block">
            <h1 class="title">IMPACT</h1>
            <p class="desc">
                100 DAY’S of continuous operations<br>
                1.1M Epiidemic and control goods and materials donatwd
            </p>
        </div>
    </div>
</div>
