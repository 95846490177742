import {Routes} from '@angular/router';
import {IndexComponent} from './views/index/index.component';
import {NewsComponent} from './views/news/news.component';
import {Module2Component} from './views/module2/module2.component';
import {Module3Component} from './views/module3/module3.component';
import {Module4Component} from './views/module4/module4.component';
import {Module6Component} from './views/module6/module6.component';
import {Module4DetailsComponent} from './views/module4-details/module4-details.component';
import {MenuComponent} from './views/menu/menu.component';
import {Module5Component} from './views/module5/module5.component';
import {LoadingComponent} from './views/loading/loading.component';
import {AuthGuard} from './auth/auth.guard';
import {UsComponent} from './views/us/us.component';
import {TerminusDetailsComponent} from './views/terminus-details/terminus-details.component';
import {TerminusDetailsContent1Component} from './views/terminus-details-content1/terminus-details-content1.component';
import {TerminusDetailsContent2Component} from './views/terminus-details-content2/terminus-details-content2.component';
import {TerminusDetailsContent3Component} from './views/terminus-details-content3/terminus-details-content3.component';
import {HuoLeiComponent} from './views/huo-lei/huo-lei.component';
import {BlogComponent } from './views/blog/blog.component';
import {BlogDetailsComponent} from './views/blog-details/blog-details.component';
import {SearchComponent} from './views/search/search.component';
import {TerminusListComponent} from './views/terminus-list/terminus-list.component';
import {TerminusPeopleComponent} from './views/terminus-people/terminus-people.component';
import {SolutionsComponent} from './views/solutions/solutions.component';
import {TerminusDetailsContent4Component} from './views/terminus-details-content4/terminus-details-content4.component';
import {TerminusDetailsContent5Component} from './views/terminus-details-content5/terminus-details-content5.component';
import {TerminusDetailsContent6Component} from './views/terminus-details-content6/terminus-details-content6.component';
import {TerminusDetailsContent7Component} from './views/terminus-details-content7/terminus-details-content7.component';
import {TerminusDetailsContent8Component} from './views/terminus-details-content8/terminus-details-content8.component';

export const routes:Routes=[
    {
        path:"index",
        component:IndexComponent,
        canActivate: [AuthGuard],
        children:[
            {
                path:"solutions",
                component:SolutionsComponent,
                children:[
                    {
                        path:"details-1",
                        component:TerminusDetailsContent1Component
                    },
                    {
                        path:"details-2",
                        component:TerminusDetailsContent2Component
                    },
                    {
                        path:"details-3",
                        component:TerminusDetailsContent3Component
                    },
                    {
                        path:"details-4",
                        component:TerminusDetailsContent4Component
                    },
                    {
                        path:"details-5",
                        component:TerminusDetailsContent5Component
                    },
                    {
                        path:"details-6",
                        component:TerminusDetailsContent6Component
                    },
                    {
                        path:"details-7",
                        component:TerminusDetailsContent7Component
                    },
                    {
                        path:"details-8",
                        component:TerminusDetailsContent8Component
                    }
                ]
            },
            {
                path:"news",
                component:NewsComponent
            },
            {
                path:"us",
                component:UsComponent
            },
            {
                path:"blog",
                component:BlogComponent,
                children:[
                    {
                        path: "details",
                        component: BlogDetailsComponent
                    }
                ]
            },
            {
                path:"center",
                component: Module6Component
            },
            {
                path:"left",
                component: Module2Component,
                children:[
                    {
                        path:"huolei",
                        component:HuoLeiComponent
                    },
                    {
                        path:"list",
                        component:TerminusListComponent,
                        children:[
                            {
                                path:"details",
                                component: TerminusDetailsComponent,
                                children:[
                                    {
                                        path:"1",
                                        component:SolutionsComponent
                                    },
                                    {
                                        path:"2",
                                        component:Module5Component
                                    },
                                    {
                                        path:"3",
                                        component:HuoLeiComponent
                                    }
                                ]
                            },
                            {
                                path:"people",
                                component:TerminusPeopleComponent
                            }
                        ]
                    },
                ]
            },
            {
                path:"right",
                component: Module5Component
            },
            {
                path:"top",
                component: Module3Component
            },
            {
                path:"search",
                component: SearchComponent,
            },
            {
                path:"bottom",
                component: Module4Component,
                children:[
                    {
                        path:"details/:index",
                        component: Module4DetailsComponent,
                    }
                ]
            }
        ]
    },
    {
        path: 'news-details/:id',
        component: NewsComponent
    },
    {
        path:"loading",
        component:LoadingComponent
    },
    {
        path:"",
        redirectTo:"loading",
        pathMatch: 'full'
    }
];
