<app-main-content class="page-module3 page" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full transition " animation transitionDelay="0"><img src="./assets/images/news-bg.jpg"></div>
    </div>
    <app-header></app-header>
    <app-contents>
        <div class="page-view">
            <h1 class="title transition">NEWS</h1>
            <div class="news-item " [style]="{backgroundImage:'url('+((newItem.cover||''))+')'}">
                <p class="subtitle" [innerHTML]="newItem.title"></p>
            </div>
            <div class="text" [innerHTML]="newItem.showreel.content">
        
            </div>
        </div>
    </app-contents>
</app-main-content>
