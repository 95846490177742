<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Environment <br/>Solution</p>
            <p class="big"></p>
        </div>
        <p class="desc">
            This integrated system provides comprehensive management for cities, including device measurement and analysis, information gathering/processing/storage/submission and online monitoring/control of pollutant sources (waste water/exhaust gas/heavy metal/noises/radiation, etc.)
        </p>
        <p class="image">
            <img src="./assets/images/terminus-details-content-image7.jpg">
        </p>
    </div>
</div>
