import {Component, OnInit} from '@angular/core';
import {ModuleComponent} from '../../class/ModuleComponent';
var data = [{'name': 'module5_product1_1', 'x': 139, 'y': 400, 'width': 430, 'height': 972, 'type': 'image'}, {
    'name': 'module5_product1_2',
    'x': 139,
    'y': 400,
    'width': 430,
    'height': 973,
    'type': 'image'
}, {'name': 'module5_product1_3', 'x': 140, 'y': 400, 'width': 429, 'height': 973, 'type': 'image'}, {
    'name': 'module5_product1_3_eye',
    'x': 257,
    'y': 508,
    'width': 54,
    'height': 44,
    'type': 'image'
}, {'name': 'module5_product1_3_eye', 'x': 421, 'y': 508, 'width': 54, 'height': 44, 'type': 'image'}, {
    'name': 'module5_product2',
    'x': 81,
    'y': 506,
    'width': 581,
    'height': 845,
    'type': 'image'
}, {'name': 'module5_product2_eye', 'x': 446, 'y': 680, 'width': 89, 'height': 87, 'type': 'image'}, {
    'name': 'module5_product2_eye',
    'x': 180,
    'y': 670,
    'width': 89,
    'height': 87,
    'type': 'image'
}, {'name': 'module5_product3_1', 'x': 0, 'y': 643, 'width': 750, 'height': 532, 'type': 'image'}, {
    'name': 'module5_product3_2',
    'x': 0,
    'y': 643,
    'width': 750,
    'height': 532,
    'type': 'image'
}];

@Component({
    selector: 'app-module5',
    templateUrl: './module5.component.html',
    styleUrls: ['./module5.component.css']
})
export class Module5Component extends ModuleComponent implements OnInit {
  exchangeStyle={
    opacity:0,
    transform:"translateY(100px)"
  };
  timer=null;
  productStyles:any[]=[];
  private _animateIndex=0;
  set animateIndex(value){
    this._animateIndex=value;
    this.animationProcess(value)
  };
  get animateIndex(){
    return this._animateIndex;
  }
  async animationProcess(value){
      this.productStyles=[];
      switch (value) {
          case 1:
              await this.$setTimeout(3000);
              this.productStyles[0]=this.exchangeStyle;
              break;
          case 3:
              await this.$setTimeout(3000);
              this.productStyles[1]=this.exchangeStyle;
              await this.$setTimeout(1000);
              this.animateIndex++;
              break;
          case 4:
              await this.$setTimeout(3000);
              this.productStyles[2]=this.exchangeStyle;
              await this.$setTimeout(1000);
              this.animateIndex=0;
              break;

      }
  }
  async $setTimeout(time){
      return new Promise(((resolve, reject) => {
          let timer=setTimeout(e=>{
              resolve(timer);
          },time)
      }))
  }
  ngOnInit(): void {
    super.ngOnInit();
    /*let divs=``;
    let styles=``;
    data.forEach(data=>{
      divs+=`<div class="${data.name} animation-item"></div>\n`;
      styles+=`.${data.name}{left:${data.x}px;top:${data.y}px;width:${data.width}px;height:${data.height}px;background-image:url(/assets/images/${data.name}.png);}\n`;
    })
    console.log(divs,'divs')
    console.log(styles,'styles')*/
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  clearTimeout(this.timer);
  }
}
