<div class="page page-module4-details transition" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele"><img src="./assets/images/module4-details-bg.jpg"/></div>
    </div>
    <div class="page-content">
       <div class="text-content">
           <h1 class="title" [innerHTML]="currentData?.title"></h1>
           <h1 class="subtitle" [innerHTML]="currentData?.subtitle"></h1>
           <p class="texts"  [innerHTML]="currentData?.texts"></p>
       </div>
        <div  class="swiper-container" [swiper]="config" [(index)]="index">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let index of [1,2,3,4]">
                    <img src="./assets/images/module4-details{{index}}-80.jpg">
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</div>
