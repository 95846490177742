<div class="page page-module5" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon-w.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele full transition" ><img src="./assets/images/module-5-layer5.png"></div>
        <div class="bg-layer-ele lt transition bg-01" ><img src="./assets/images/module-5-layer1.png"></div>
        <div class="bg-layer-ele rt transition bg-02" ><img src="./assets/images/module-5-layer4.png"></div>
        <div class="bg-layer-ele rt transition bg-03" ><img src="./assets/images/module-5-layer2.png"></div>
        <div class="bg-layer-ele rb transition bg-04" ><img src="./assets/images/module-5-layer3.png"></div>
    </div>
    <div class="title transition">
        <p class="small">Our </p>
        <p class="big">Products</p>
    </div>
    <div class="rect-bg transition">
        <img src="./assets/images/module-5-big.png">
    </div>
    <div class="animations" *ngIf="!isBack">
        <div class="transition" [style]="productStyles[0]" animation (transitionEnd)="animateIndex=2" *ngIf="[0,1].includes(animateIndex)">
            <div class="module5_product1_1 animation-item animated fadeIn" style="background-image:url(./assets/images/module5_product1_1.png);" animation animationDelay="1000"></div>
            <div class="module5_product1_2 animation-item animated fadeIn"  style="background-image:url(./assets/images/module5_product1_2.png);" animation animationDelay="2000"></div>
            <div class="module5_product1_3 animation-item animated fadeIn"  style="background-image:url(./assets/images/module5_product1_3.png);"  animation animationDelay="3000" (animationEnd)="animateIndex=1"></div>
            <div class="module5_product1_3_eye l  animation-item eye animated"  style="background-image:url(./assets/images/module5_product1_3_eye.png);" *ngIf="animateIndex===1"  ></div>
            <div class="module5_product1_3_eye r animation-item eye animated"  style="background-image:url(./assets/images/module5_product1_3_eye.png);" *ngIf="animateIndex===1"  ></div>
        </div>
        <div class="transition" *ngIf="[2,3].includes(animateIndex)" [style]="productStyles[1]">
            <div class="module5_product2 animation-item animated fadeInUp" style="background-image:url(./assets/images/module5_product2.png);" animation (animationEnd)="animateIndex=3"></div>
            <div class="module5_product2_eye l animation-item eye animated" style="background-image:url(./assets/images/module5_product2_eye.png);"  *ngIf="animateIndex===3" ></div>
            <div class="module5_product2_eye r animation-item eye animated" style="background-image:url(./assets/images/module5_product2_eye.png);"  *ngIf="animateIndex===3" ></div>
        </div>
        <div *ngIf="[4].includes(animateIndex)" class="transition" [style]="productStyles[2]">
            <div class=" fadeIn animated" >
                <div class="module5_product3_1 animation-item" style="background-image:url(./assets/images/module5_product3_1.png);"></div>
                <div class="module5_product3_2 animation-item animated long infinite flash" style="background-image:url(./assets/images/module5_product3_2.png);"></div>
            </div>
        </div>
    </div>
</div>
