<div class="page page-module6" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele rt transition bg-01" animation transitionDelay="0"><img src="./assets/images/vision-layer1.png"></div>
        <div class="bg-layer-ele rb transition bg-01" animation transitionDelay="0"><img src="./assets/images/vision-layer3.png"></div>
    </div>
    <div class="title transition">
        <p class="small">Our Vision </p>
    </div>
    <div class="rect-bg transition">
        <img src="./assets/images/module-6-big.png">
    </div>
    <div class="ball transition" animation [transitionDelay]="isBack?0:1000">
        <p class="ball-img pulse animated infinite long"><img src="./assets/images/module-6-big-ball.png"></p>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000" transitionDuration="500">
        <div class="text-bg"><img src="./assets/images/vision-text-bg.png"></div>
        <p class="texts">We aim to use our AIoT engine to integrate standalone products into smart solutions, eventually paving the way to a world of interconnected AI CITY networks.</p>
        <p class="arrow">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21.9px" height="48px" viewBox="0 0 21.9 48" style="enable-background:new 0 0 21.9 48;" xml:space="preserve">
                <style type="text/css">
                    .st0{fill:#000097;}
                </style>
                                <defs>
                </defs>
                <polygon class="st0" points="21.9,24 0,0 0,48 "/>
            </svg>
        </p>
    </div>
</div>
