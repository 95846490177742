import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { routes } from './routes';
import { IndexComponent } from './views/index/index.component';
import { HeaderComponent } from './components/header/header.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { ContentsComponent } from './components/contents/contents.component';
import { FontAwesomeModule } from 'ngx-icons';
import { AnimationDirective } from './directive/animation.directive';
import { Module2Component } from './views/module2/module2.component';
import { Module4Component } from './views/module4/module4.component';
import { Module4DetailsComponent } from './views/module4-details/module4-details.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Module6Component } from './views/module6/module6.component';
import { NewsComponent } from './views/news/news.component';
import { Module3Component } from './views/module3/module3.component';
import { MenuComponent } from './views/menu/menu.component';
import { Module5Component } from './views/module5/module5.component';
import { LoadingComponent } from './views/loading/loading.component';
import { UsComponent } from './views/us/us.component';
import { TerminusDetailsComponent } from './views/terminus-details/terminus-details.component';
import { TerminusDetailsContent1Component } from './views/terminus-details-content1/terminus-details-content1.component';
import { TerminusDetailsContent3Component } from './views/terminus-details-content3/terminus-details-content3.component';
import { TerminusDetailsContent2Component } from './views/terminus-details-content2/terminus-details-content2.component';
import { HuoLeiComponent } from './views/huo-lei/huo-lei.component';
import { BlogComponent } from './views/blog/blog.component';
import { BlogDetailsComponent } from './views/blog-details/blog-details.component';
import { SearchComponent} from './views/search/search.component';
import { TerminusListComponent } from './views/terminus-list/terminus-list.component';
import { TerminusPeopleComponent } from './views/terminus-people/terminus-people.component';
import { SolutionsComponent } from './views/solutions/solutions.component';
import { TerminusDetailsContent4Component } from './views/terminus-details-content4/terminus-details-content4.component';
import { TerminusDetailsContent5Component } from './views/terminus-details-content5/terminus-details-content5.component';
import { TerminusDetailsContent6Component } from './views/terminus-details-content6/terminus-details-content6.component';
import { TerminusDetailsContent7Component } from './views/terminus-details-content7/terminus-details-content7.component';
import { TerminusDetailsContent8Component } from './views/terminus-details-content8/terminus-details-content8.component';
import { BgAudioComponent } from './components/bg-audio/bg-audio.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    MainContentComponent,
    ContentsComponent,
    AnimationDirective,
    Module2Component,
    Module4Component,
    Module4DetailsComponent,
    Module6Component,
    NewsComponent,
    Module3Component,
    MenuComponent,
    Module5Component,
    LoadingComponent,
    UsComponent,
    TerminusDetailsComponent,
    TerminusDetailsContent1Component,
    TerminusDetailsContent3Component,
    TerminusDetailsContent2Component,
    HuoLeiComponent,
    BlogComponent,
    BlogDetailsComponent,
    SearchComponent,
    TerminusListComponent,
    TerminusPeopleComponent,
    SolutionsComponent,
    TerminusDetailsContent4Component,
    TerminusDetailsContent5Component,
    TerminusDetailsContent6Component,
    TerminusDetailsContent7Component,
    TerminusDetailsContent8Component,
    BgAudioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    RouterModule.forRoot(routes,{useHash:true}),
      SwiperModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
