<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0">
            <img src="./assets/images/terminus-details-bg2.jpg">
        </div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Building<br/>Solution</p>
            <p class="big"></p>
        </div>
        <div class="text-block">
            <p class="subtitle">SMART OFFICE BUILDING</p>
            <p class="desc">
                Terminus Group AIoT Smart Office Building Solution is designed to create a pleasant office environment, by using AIoT, cloud computing and computer vision technologies to solve varied problems of conventional office buildings, such as access control, office works, security and parking. With comfortable, efficient, energy-saving, safe and smart end-to-end services, it helps to make the management of office buildings more orderly, intensive and effective.
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">ABAS ABAS PLATFORM</p>
            <p class="desc">
                ABAS BI+Operation Management<br/>
                Real-time remote monitoring, alarm,energy saving report <br/>
                ABAS BI+ BIM ABAS BI+ BIM
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">ABAS BI+ BIM</p>
            <p class="desc">
                Building Information Managment of whole life cycle
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">ABAS BI+ Big Data Analysis</p>
            <p class="desc">
                Data mining of Building operation,reflecting the value of data
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">ABAS BI Client-side </p>
            <p class="desc">
                On-site inspection, work sheet and maintenance
            </p>
            <p class="image">
                <img src="./assets/images/terminus-details-content-image2.jpg">
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">CLIENT VALUE </p>
            <p class="desc">
                Energy Saving<br/>
                Property Management Costs<br/>
                saving by 10% - 30%<br/>
                Equipment Maintenance Costs<br/>
                saving by 20% - 40%<br/>
                Energy saving by 20% - 30%<br/>
                Reducing dependence on outsourcing
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">Revenue- Sharing</p>
            <p class="desc">
                Investment and financing services<br/>
                Engineering design implementation<br/>
                Managed operation service<br/>
                Integrated platform and service
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">Certifications</p>
            <p class="desc">
                Green Building Certification<br/>
                Intelligent authentication of ELV<br/>
                Carbon emission certification<br/>
                Integrated intelligent system
            </p>
        </div>
        <div class="text-block">
            <p class="subtitle">Quality Improvement </p>
            <p class="desc">
                Improve operational efficiency<br/>
                Elevate Property Value<br/>
                Increase environmental comfort
            </p>
        </div>
    </div>
</div>
