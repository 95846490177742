<div class="page page-terminus-content" [class.scatter]="scatter">
    <div class="back" (click)="back()" *ngIf="!isBack"><img src="./assets/images/back-icon.png"></div>
    <div class="bg-layer">
        <div class="bg-layer-ele  transition full" animation transitionDelay="0"><img src="./assets/images/terminus-details-bg.jpg"></div>
        <div class="bg-layer-ele  transition lt bg-03" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer3.png"></div>
        <div class="bg-layer-ele  transition lt bg-02" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer2.png"></div>
        <div class="bg-layer-ele  transition lt bg-01" animation transitionDelay="0"><img src="./assets/images/terminus-details-layer1.png"></div>
    </div>
    <div class="text-content transition" animation [transitionDelay]="isBack?0:1000">
        <div class="title ">
            <p class="small">Smart Tourism <br/>Solution</p>
            <p class="big"></p>
        </div>
        <p class="desc">
            Terminus Group Smart Tourism Solution not only improves tourist experiences, but also makes the resort management smarter, more efficient and more automated. Meanwhile, the circulation of capital and information in tourism is accelerated by Smart Access, Senseless Payment and Information Channel Management, which in turn drives the development of whole industry.
        </p>
        <p class="image">
            <img src="./assets/images/terminus-details-content-image8.jpg">
        </p>
    </div>-
</div>
