import {Directive, ElementRef, EventEmitter, Input, Output,OnInit,ComponentRef} from '@angular/core';

@Directive({
  selector: '[animation]'
})
export class AnimationDirective implements OnInit{
  @Input() set animationDelay(value){
    let {nativeElement}=this.el;
    nativeElement.style.animationDelay=`${value}ms`;
    nativeElement.style.webkitAnimationDelay=`${value}ms`;
  };
  @Input() set transitionDelay(value){
    let {nativeElement}=this.el;
    nativeElement.style.transitionDelay=`${value}ms`;
    nativeElement.style.weikitTransitionDelay=`${value}ms`;
  };
  @Input() set animationDuration(value){
    let {nativeElement}=this.el;
    nativeElement.style.animationDuration=`${value}ms`;
    nativeElement.style.webkitAnimationDuration=`${value}ms`;
  };
  @Input() set transitionDuration(value){
    let {nativeElement}=this.el;
    nativeElement.style.transitionDuration=`${value}ms`;
    nativeElement.style.webkitTransitionDuration=`${value}ms`;
  };

  @Output() animationEnd:EventEmitter<any>=new EventEmitter<any>();
  @Output() transitionEnd:EventEmitter<any>=new EventEmitter<any>();
  constructor(private el: ElementRef) {
  }
  ngOnInit(): void {
    let {nativeElement}=this.el;
    nativeElement.addEventListener('animationEnd',e=>{this.animationEnd.emit(e)});
    nativeElement.addEventListener('webkitAnimationEnd',e=>{this.animationEnd.emit(e)});

    nativeElement.addEventListener('transitionEnd',e=>{this.animationEnd.emit(e)});
    nativeElement.addEventListener('webkitTransitionEnd',e=>{this.transitionEnd.emit(e)});
  }

}
